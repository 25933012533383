import React, { useState, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { Button } from './Button';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';



const HeroSection = styled.section`
  height: 100vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
  background: none; /* Ensure there's no background */
`;

const HeroWrapper = styled.div`
height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;


const HeroSlide = styled.div`
 z-index: 1;
  width: 100%;
  height: 100%;
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateX(100%); }
  to { opacity: 1; transform: translateX(0); }
`;

const slideIn = keyframes`
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;


const slideOut = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
  
`;

const contentSlideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1;  }
`;


const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  will-change: transform, opacity;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.58) 0%, rgba(0, 10, 35, 0.58) 100%);
    backdrop-filter: blur(11.5px);
    pointer-events: none;
  }
`;

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
  
  animation: ${({ active }) => (active ? slideIn : fadeOut)} 0.8s ease forwards;
`;


const HeroContent = styled.div`
   position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 968px;
  width: calc(100% - 100px);
  color: #fff;
  text-align: center;
  animation: ${({ active }) => active && css`${contentSlideIn} 0.8s ease forwards`};
  h3 {
    color: #F2F1F7;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }

  h1 {
    color: #F2F1F7;
    font-size: 64px;
    font-weight: 900;
    line-height: 113%;
    text-transform: uppercase;
    max-width: 968px;
  }

  p {
    margin: 18px 0 64px;
    color: #F2F1F7;
    font-size: 20px;
    font-weight: 600;
    max-width: 473px;
  }
`;

const Arrow = styled(IoMdArrowRoundForward)`
  margin-left: 0.5rem;
`;

const SliderButtons = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 10;
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${props => (props.active ? '#D9D9D9' : '#F2F1F7')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  /* &:hover {
    background-color: #cd853f;
  } */
`;

const arrowButton = css`
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  border: 2px solid #F2F1F7;
  border-radius: 50px;
  padding: 10px;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  ${props => props.disabled && css`
    cursor: not-allowed;
    border: 2px solid #D9D9D9;
  `}
`;

const PrevArrow = styled(IoArrowBack)`
  ${arrowButton}
  margin-right: 1rem;
`;

const NextArrow = styled(IoArrowForward)`
  ${arrowButton}
  margin-left: 1rem;
`;

function Hero({ slides }) {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const timeoutRef = useRef(null);

  useEffect(() => {
    const nextSlide = () => setCurrent(current === length - 1 ? 0 : current + 1);

    timeoutRef.current = setTimeout(nextSlide, 5000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [current, length]);

  const handleNext = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const handlePrev = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const goToSlide = index => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setCurrent(index);
  };

  return (
    <HeroSection>
      <HeroWrapper>
        {slides.map((slide, index) => (
          <HeroSlide key={index}>
            {index === current && (
              <HeroSlider>
                <HeroImage src={slide.image} alt={slide.alt} active={index === current} />
                <HeroContent active={index === current}>
                  <h3>{slide.subtitle}</h3>
                  <h1>{slide.title}</h1>
                  <p>{slide.description}</p>
                  <Button to={slide.path}>
                    {slide.label}
                    <Arrow />
                  </Button>
                </HeroContent>
              </HeroSlider>
            )}
          </HeroSlide>
        ))}
        <SliderButtons>
          <PrevArrow onClick={handlePrev} />
          <DotsWrapper>
            {slides.map((_, index) => (
              <Dot key={index} active={index === current} onClick={() => goToSlide(index)} />
            ))}
          </DotsWrapper>
          <NextArrow onClick={handleNext} />
        </SliderButtons>
      </HeroWrapper>
    </HeroSection>
  );
}

export default Hero;
