export const SliderData =[
  {
    subtitle:'31 Décembre 2024',
    title:'dive annual party',
    description:'Bars, Clubs & Restaurants la journée, Villa avec piscine et DJ Set (toute) la nuit',
    price:'4,280,000R$',
    path:'https://card.dive.paris',
    label:'Je participe',
    image:"https://dive.paris/wp-content/uploads/2024/10/Group-675.png",
    alt:'House'
  },
  {
    subtitle:'prochaine expérience',
    title:'ça arrive bientôt',
    description:'Vous voulez proposer la votre ?',
    price:'9,280,000R$',
    path:'https://dive.paris/contact',
    label:'Nous contacter',
    image:"https://dive.paris/wp-content/uploads/2024/10/Rectangle-134.png",
    alt:'House'
  },
  
  
]
/* export const SliderDataTwo =[
  {
    title:'Luxury Villa in SaoPaul-DP, Brasil',
    price:'6,280,000R$',
    path:'/homes',
    label:'View Home',
    image:ImageFive,
    alt:'House'
  },
  {
    title:'Villa in Piaui, Brasil',
    price:'9,280,000R$',
    path:'/homes',
    label:'View Home',
    image:ImageSix,
    alt:'House'
  },
  {
    title:'House in Rio de Janeiro, Brasil',
    price:'66,280,000R$',
    path:'/homes',
    label:'View Home',
    image:ImageSeven,
    alt:'House'
  },
  {
    title:'Luxury House in Curtiba, Brasil',
    price:'3,280,000R$',
    path:'/homes',
    label:'View Home',
    image:ImageEight,
    alt:'House'
  },
] */