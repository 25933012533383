import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const Button = styled(Link)`
background: ${({ primary }) => (primary ? '#1E1E1E' : '#FFF')};
white-space: nowrap;
outline: none;
border: none;
min-width: 154px;
max-width: 200px;
cursor: pointer;
text-decoration: none !important;
/* transition: center; */
display: flex;
justify-content: center;
align-items: center;
padding: 9px 61px;
color: ${({ primary }) => (primary ? '#fff' : '#1E1E1E')};
text-align: center;
font-size: ${({ big }) => (big ? '20px' : '14px')};
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
border-radius: 30px;
&:hover {
  transform: translateY(-2px);
}
&:visited {
  text-decoration: none !important;
}
`;