import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { menuData } from '../data/MenuData';
import { Button } from './Button';
import Bars from '../images/bars.svg';


const Nav = styled.nav `
height: 60px;
display:flex;
justify-content:space-between;
padding:1rem 2rem;
z-index:100;
position:fixed;
width:100%;

`;
const NavLink = css`
color:#fff;
display:flex;
align-items:center;
padding: 0 1rem;
height: 100%;
cursor:pointer;
text-decoration:none;
`;
const Logo = styled(Link)` 
${NavLink}
padding: 0;
`;
const MenuBars = styled.i `
display:none;
@media screen and (max-width:768px){
  display:block;
  background-image:url(${Bars});
  background-size:contain;
  height:28px;
  width:28px;
  cursor:pointer;
  position:absolute;
  top:12px;
  right:19px;
  transform:translate(-30%, 20%);
}
`;
const NavMenu = styled.div `
display:flex;
align-items:center;
margin-right: -48px;

@media screen and (max-width:768px){
  display:none;
}
`;
const NavMenuLinks = styled(Link)`
${NavLink}
`;
const NavBtn = styled(Link) `
background: ${({ primary }) => (primary ? '#1E1E1E' : '#FFF')};
white-space: nowrap;
outline: none;
border: none;
min-width: 154px;
max-width: 200px;
cursor: pointer;
text-decoration: none !important;
/* transition: center; */
display: flex;
justify-content: center;
align-items: center;
padding: 9px 61px;
color: ${({ primary }) => (primary ? '#fff' : '#1E1E1E')};
text-align: center;
font-size: ${({ big }) => (big ? '20px' : '14px')};
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
border-radius: 30px;
&:hover {
  transform: translateY(-2px);
}
&:visited {
  text-decoration: none !important;
}
/* display:flex;
align-items:center;
margin-right:30px; */
@media screen and (max-width:768px){
  display:none
}
`;

const Navbar = ({toggle}) => {
  return (
    <Nav>
      <Logo to={"https://dive.paris"}>
        <svg width="83" height="14" viewBox="0 0 83 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37 0H43.0487C45.1176 0 46.7319 0.521415 47.8916 1.56425C49.328 2.84171 50.0462 4.62104 50.0462 6.90223C50.0462 9.26164 49.3741 11.0866 48.03 12.3771C46.9098 13.459 45.2428 14 43.0289 14H37V0ZM40.6964 3.01117V10.9888H42.7324C43.8921 10.9888 44.7486 10.6955 45.3021 10.1089C45.961 9.39199 46.2905 8.3622 46.2905 7.01955C46.2905 5.49441 45.8358 4.38641 44.9265 3.69553C44.3072 3.23929 43.4901 3.01117 42.4754 3.01117H40.6964Z" fill="#F2F1F7"/>
  <path d="M55.7147 0V14H52.0182V0H55.7147Z" fill="#F2F1F7"/>
  <path d="M70.2702 0L65.6645 14H61.4147L56.9276 0H60.8019L62.0867 4.06704C62.8115 6.34823 63.3123 8.45996 63.589 10.4022C63.8921 8.44693 64.3995 6.34171 65.1111 4.08659L66.3959 0H70.2702Z" fill="#F2F1F7"/>
  <path d="M82.8221 0V3.01117H75.1921V5.33799H82.1698V8.21229H75.1921V10.9888H83V14H71.4956V0H82.8221Z" fill="#F2F1F7"/>
  <path d="M4.50321 11.5145C6.7856 11.5145 9.28756 10.2625 10.9826 8.36175C12.6788 10.2625 15.1818 11.5145 17.4642 11.5145C19.6379 11.5145 21.9609 10.2647 21.9609 6.75725C21.9609 3.24984 19.6379 2.00214 17.4642 1.99998C15.1818 1.99998 12.6799 3.25201 10.9837 5.15275C9.28866 3.25201 6.7867 1.99998 4.50431 1.99998C2.3295 1.99998 0.00429226 3.24984 0.00429195 6.75725C0.00429164 10.2647 2.3295 11.5145 4.50321 11.5145ZM17.4642 3.90181C19.1241 3.90181 19.9848 4.86299 19.9848 6.75725C19.9848 8.65151 19.1241 9.6127 17.4642 9.6127C15.6275 9.6127 13.4967 8.43852 12.1869 6.75725C13.4967 5.07599 15.6275 3.90181 17.4642 3.90181ZM4.50321 3.90181C6.33988 3.90181 8.47077 5.07599 9.78049 6.75725C8.47187 8.43852 6.33878 9.6127 4.50321 9.6127C2.84328 9.6127 1.98039 8.65151 1.98039 6.75725C1.98039 4.86299 2.84329 3.90181 4.50321 3.90181Z" fill="url(#paint0_linear_1459_236)"/>
  <defs>
    <linearGradient id="paint0_linear_1459_236" x1="10.9826" y1="1.99998" x2="10.9826" y2="11.5145" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="#A3A3A3"/>
    </linearGradient>
  </defs>
</svg></Logo>
      <MenuBars onClick={toggle}/>
      <NavMenu>
           {menuData.map((item,index)=>(
            <NavMenuLinks to={item.link} key={index}>
            {item.title}
            </NavMenuLinks>
           ))}
      </NavMenu>
      <NavBtn to="https://card.dive.paris" primary='true'>
      Obtenir le Pass
      </NavBtn>
    </Nav>
  )
}

export default Navbar