import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto Flex', sans-serif; 
}

html, body {
  overflow-x: hidden;
}
`;

export default GlobalStyle;